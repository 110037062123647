<template>
    <div class="container">
        <label>
            Atlassian
            <a
                v-if="!is_atlassian_authed"
                href="https://id.atlassian.com/manage-profile/security/api-tokens"
                target="_blank"
            >
                Generate your token
            </a>
        </label>

        <div class="right">
            <el-button
                v-if="loading"
                v-loading="true"
                disabled
                class="spinner"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0)"
            >
                <i class="el-icon-loading" style="visibility: hidden;" />
            </el-button>
            <template v-else>
                <template v-if="is_atlassian_authed">
                    <el-button disabled>
                        Connected
                    </el-button>
                    <el-button
                        style="flex-grow: inherit;"
                        icon="el-icon-delete"
                        @click="handleDisconnectAtlassian"
                    />
                </template>
                <template v-else>
                    <div class="col">
                        <el-input
                            v-model="atlassian_api_user"
                            size="mini"
                            placeholder="Email"
                            autocomplete="off"
                        />
                        <el-input
                            v-model="atlassian_api_token"
                            size="mini"
                            placeholder="API token"
                            autocomplete="off"
                        />
                    </div>
                    <el-button
                        :disabled="!atlassian_api_user || !atlassian_api_token"
                        @click="handleConnectAtlassian"
                    >
                        Connect
                    </el-button>
                </template>
            </template>
        </div>
    </div>
</template>
<script>
import userMixin from '@/mixins/user.mixin';
import {
    connectAtlassian,
    disconnectAtlassian,
    redirectUserToAtlassian,
} from '@/api/atlassian';
import {SUPPORTED_FEATURES} from '@/store/modules/feature-toggles';

export default {
    name: 'atlassian-integration',
    components: {},
    mixins: [userMixin],
    data() {
        return {
            loading: false,

            atlassian_api_user: this.$store.getters.user.email,
            atlassian_api_token: null,

            SUPPORTED_FEATURES: SUPPORTED_FEATURES,
        };
    },
    methods: {
        refreshUser() {
            return this.$store.dispatch('Refresh', true);
        },
        async handleConnectAtlassian() {
            this.loading = true;

            try {
                await connectAtlassian(
                    this.atlassian_api_user,
                    this.atlassian_api_token
                );
                await this.refreshUser();
            } catch (e) {
                console.log(e);
            }

            this.loading = false;
        },
        async handleDisconnectAtlassian() {
            this.loading = true;

            try {
                await disconnectAtlassian();
                await this.refreshUser();
            } catch (e) {
                console.log(e);
            }

            this.loading = false;
        },
    },
};
</script>
