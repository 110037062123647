<template>
    <div>
        <h4>Details:</h4>
        <div class="container">
            <label>Logo</label>
            <div class="row" style="width: 80%;">
                <file-uploader
                    folder="organisation"
                    @uploaded="(val) => update('logo', val)"
                    style="margin-bottom: -5px;"
                />
                <img class="logo" :src="$store.state.organisation.logo" />
            </div>
        </div>

        <div class="container description">
            <p>
                Adjust these fields to customize how your organization's
                information appears in various areas, ensuring consistency and
                accuracy across all outputs.
            </p>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <label>Name</label>
                        <el-input
                            v-model="$store.state.organisation.name"
                            size="medium"
                            @change="(val) => update('name', val)"
                        />
                    </div>
                    <div class="row">
                        <label>Email</label>
                        <el-input
                            v-model="$store.state.organisation.email"
                            size="medium"
                            @change="(val) => update('email', val)"
                        />
                    </div>
                    <div class="row">
                        <label>Phone</label>
                        <el-input
                            v-model="$store.state.organisation.phone"
                            size="medium"
                            @change="(val) => update('phone', val)"
                        />
                    </div>
                    <div class="row">
                        <label>Address</label>
                        <el-input
                            v-model="$store.state.organisation.address"
                            size="medium"
                            @change="(val) => update('address', val)"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <label>Time zone</label>
                <div style="width: 80%;">
                    {{ timezone }}
                    <p style="margin-top: 5px; margin-bottom: 0;">
                        Determines day boundaries for all operations in your
                        organisation.<br />
                        If you require changes to your organisation's time zone,
                        please contact your administrator.
                    </p>
                </div>
            </div>
        </div>

        <div class="container">
            <label>Color</label>
            <color-picker
                :value="color"
                :show_dialog="show_dialog"
                @input="(val) => update('color', val)"
            />
        </div>

        <hr />

        <h4>Emails:</h4>
        <div class="container description">
            <p>
                Configure these fields to ensure that automated emails sent from
                the platform are properly addressed, helping to manage responses
                and maintain organized communication channels.
            </p>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <label>General</label>
                        <el-input
                            :placeholder="`no-reply@${email_domain}`"
                            v-model="$store.state.organisation.replyto_general"
                            size="medium"
                            @change="(val) => update('sender_general', val)"
                        />
                    </div>
                    <div class="row">
                        <label>Invoices</label>
                        <el-input
                            :placeholder="
                                $store.state.organisation.replyto_general ||
                                `no-reply@${email_domain}`
                            "
                            v-model="$store.state.organisation.replyto_invoice"
                            size="medium"
                            @change="(val) => update('sender_invoice', val)"
                        />
                    </div>
                </div>
            </div>
        </div>

        <h4>Documents:</h4>
        <div class="container description">
            <p>
                Adjust these settings to tailor the content and appearance of
                documents created by the platform.
            </p>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <label>Agreement</label>
                        <el-input
                            type="textarea"
                            :rows="4"
                            placeholder="Estimate agreement clause"
                            v-model="$store.state.organisation.agreement_clause"
                            size="medium"
                            @change="(val) => update('agreement_clause', val)"
                        />
                    </div>
                    <div class="row">
                        <label>Footer</label>
                        <el-input
                            placeholder="None"
                            v-model="$store.state.organisation.doc_footer"
                            size="medium"
                            @change="(val) => update('doc_footer', val)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FileUploader from '@/components/generic/FileUploader';
import ColorPicker from '@/components/generic/ColorPicker';

export default {
    components: {ColorPicker, FileUploader},
    props: {
        show_dialog: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        color() {
            return this.$store.state.organisation?.color || null;
        },
        timezone() {
            return process.env.VUE_APP_TIMEZONE;
        },
        email_domain() {
            return this.$store.state.organisation?.email?.split('@')[1];
        },
    },
    methods: {
        update(key, val) {
            console.log('Updating organisation...', key, val);
            this.$fire.doc('system/organisation').update({
                [key]: val,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;

    .el-input {
        max-width: 80%;
    }

    img.logo {
        width: 150px;
    }

    .el-textarea {
        width: 100%;
        max-width: 80%;
        word-break: normal;
    }
}

hr {
    margin: 40px 0;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}
</style>
