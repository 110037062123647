import request from '@/utils/request';

// {{host}}/atlassian/redirect
export function redirectUserToAtlassian() {
    console.log('Redirecting to Atlassian account...');
    return request({
        url: '/atlassian/redirect',
        method: 'get',
    });
}

// {{host}}/atlassian/authenticate
export function handleCallbackFromAtlassian(code, state) {
    console.log('Authenticating Atlassian account...');
    return request({
        url: '/atlassian/authenticate',
        method: 'post',
        data: {
            code,
            state,
        },
    });
}

// {{host}}/atlassian/connect
export function connectAtlassian(user, token) {
    console.log('Connecting Atlassian account...');
    return request({
        url: '/atlassian/connect',
        method: 'post',
        data: {
            user,
            token,
        },
    });
}

// {{host}}/atlassian/disconnect
export function disconnectAtlassian() {
    console.log('Disconnecting Atlassian account...');
    return request({
        url: '/atlassian/disconnect',
        method: 'delete',
    });
}

// {{host}}/jira/{organisation}/projects
export function getJiraProjects(org, startAt = 0) {
    return request({
        url: `/jira/${org}/projects`,
        method: 'get',
    });
}

// {{host}}/jira/{organisation}/project/{project}
export function getJiraProjectByKey(org, project) {
    return request({
        url: `/jira/${org}/project/${project}`,
        method: 'get',
    });
}

// {{host}}/confluence/{organisation}/spaces
export function getConfluenceSpaces(org) {
    return request({
        url: `/confluence/${org}/spaces`,
        method: 'get',
    });
}

// {{host}}/confluence/{organisation}/tasks/space/{space}/user
export function getConfluenceTasksForUser(project, options = {}) {
    return request({
        url: `/confluence/${project.confluence_org}/tasks/space/${project.confluence_space}/user?complete=true`,
        method: 'get',
        ...options,
    });
}

// {{host}}/confluence/{organisation}/tasks/space/{space}
export function getConfluenceTasksForProject(project) {
    return request({
        url: `/confluence/${project.confluence_org}/tasks/space/${project.confluence_space}?complete=true`,
        method: 'get',
    });
}

// {{host}}/confluence/{org}/content/{content}/task/{task}
export function updateConfluenceTask(org, content, task, status) {
    return request({
        url: `/confluence/${org}/content/${content}/task/${task}`,
        data: {
            status,
        },
        method: 'put',
    });
}

// {{host}}/jira/cache
export function updateGlobalJiraCache() {
    return request({
        url: '/jira/cache',
        method: 'post',
    });
}

// {{host}}/jira/cache/{project}
export function updateProjectJiraCache(project_id) {
    return request({
        url: `/jira/cache/${project_id}`,
        method: 'post',
    });
}

// {{host}}/jira/{organisation}/issues/search
export function getJiraIssuesCompletedInWindowForProject(
    org,
    project,
    updatedAfter,
    updatedBefore,
    startAt = 0
) {
    const data = {
        jql: `project = ${project} and status = Done and updated >= ${updatedAfter} and updated <= ${updatedBefore}`,
        startAt,
    };
    return request({
        url: `/jira/${org}/issues/search`,
        method: 'post',
        data,
    });
}

// {{host}}/jira/{organisation}/issue
export function createJiraIssue(org, project, summary, fields, type = 'Task') {
    const data = {
        data: {
            project: {key: project},
            issuetype: {name: type},
            summary,
            ...fields,
        },
    };

    return request({
        url: `/jira/${org}/issue`,
        method: 'post',
        data,
    });
}

// {{host}}/jira/{organisation}/issues
export function createBulkJiraIssues(project, data) {
    return request({
        url: `/jira/${project.jira_org}/issues`,
        method: 'post',
        data,
    });
}
