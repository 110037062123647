import { render, staticRenderFns } from "./base-container.vue?vue&type=template&id=26fb28ee&scoped=true"
import script from "./base-container.vue?vue&type=script&lang=js"
export * from "./base-container.vue?vue&type=script&lang=js"
import style0 from "./base-container.vue?vue&type=style&index=0&id=26fb28ee&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26fb28ee",
  null
  
)

export default component.exports