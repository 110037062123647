var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.onEscapePress.apply(null, arguments)}}},[_c('rendered-input',{ref:"input",attrs:{"project":_vm.selected_project,"is_todo":true,"placeholder":"Type here to add a todo"},on:{"enter":_vm.addTodo,"backspace":_vm.onBackspacePress,"keyup":_vm.onKeyUp},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}},[_c('template',{slot:"prepend"},[(_vm.show_user)?_c('span',{staticClass:"user",class:{
                    'wants-delete': _vm.wants_delete_selected_user,
                    'can-delete': !!_vm.selected_user_data,
                },on:{"click":_vm.clearSelectedUser}},[_c('el-popover',{attrs:{"placement":"bottom","trigger":"click","popper-class":"users_suggestions"}},[_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(_vm.display_selected_user)+" ")]),_c('ul',_vm._l((_vm.users),function(usr,idx){return _c('li',{key:usr.id,class:{
                                active:
                                    _vm.selected_user_data &&
                                    _vm.selected_user_data.id === usr.id,
                                selected:
                                    _vm.selected_user_index > -1 &&
                                    idx === _vm.selected_user_index,
                            },on:{"click":function($event){return _vm.manualSelectUser(idx)}}},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(usr.name)+" ")])])}),0)])],1):_vm._e(),(_vm.selected_project && _vm.show_project)?_c('span',{staticClass:"project",class:{
                    'wants-delete': _vm.wants_delete_selected_project,
                    'can-delete': !_vm.force_project_id,
                },on:{"click":_vm.clearSelectedProject}},[_vm._v(" "+_vm._s(_vm.selected_project.ref)+" ")]):_vm._e()]),(_vm.show_component_picker && _vm.selected_project)?_c('template',{slot:"append"},[_c('div',{staticClass:"action-icon",class:{
                    'can-delete': !!_vm.selected_component,
                },on:{"click":_vm.handleClickOnComponent}},[_c('el-tooltip',{attrs:{"open-delay":200,"content":"Link Component","placement":"top","effect":"light","popper-class":"todo-popper"}},[(!_vm.selected_component)?_c('i',{staticClass:"el-icon-link"}):_c('span',[_vm._v(_vm._s(_vm.selected_component.name))])]),_c('component-picker',{ref:"component",staticClass:"component-picker",attrs:{"project_id":_vm.selected_project.id,"component":_vm.selected_component},on:{"change":_vm.selectComponent}})],1)]):_vm._e(),(_vm.show_date_picker)?_c('template',{slot:"append"},[_c('div',{staticClass:"action-icon-group"},[_c('div',{staticClass:"action-icon",class:{
                        'can-delete': !!_vm.due_by_date,
                    },on:{"click":_vm.handleClickOnDate}},[_c('el-tooltip',{attrs:{"open-delay":200,"content":"Due Date","placement":"top","effect":"light","popper-class":"todo-popper"}},[(!_vm.due_by_date)?_c('i',{staticClass:"el-icon-date"}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("relativeDate")(_vm.due_by_date)))])])],1)]),_c('el-date-picker',{ref:"due_by",staticClass:"date-picker",class:{
                    'has-date-set': !!_vm.due_by_date,
                    'date-not-set': !_vm.due_by_date,
                },attrs:{"default-value":new Date(),"disabled":!_vm.is_active,"picker-options":_vm.pickerOptions},on:{"change":(val) => _vm.update('due_by', val)},model:{value:(_vm.due_by_date),callback:function ($$v) {_vm.due_by_date=$$v},expression:"due_by_date"}})],1):_vm._e(),_c('template',{slot:"append"},[_c('el-popover',{attrs:{"placement":"bottom","trigger":"click"},on:{"show":_vm.showHours,"hide":_vm.selectHours},model:{value:(_vm.show_hours_input),callback:function ($$v) {_vm.show_hours_input=$$v},expression:"show_hours_input"}},[_c('div',{staticClass:"action-icon",attrs:{"slot":"reference"},slot:"reference"},[_c('el-tooltip',{attrs:{"open-delay":200,"content":"Time Estimate","placement":"top","effect":"light","popper-class":"todo-popper"}},[(_vm.selected_hours)?_c('span',[_vm._v(_vm._s(_vm.selected_hours)+"h")]):_c('i',{staticClass:"el-icon-time"})])],1),_c('numeric-input',{ref:"hours",attrs:{"value":_vm.selected_hours},on:{"change":_vm.selectHours,"input":_vm.inputHours}})],1)],1)],2),(
            _vm.show_project && _vm.projects_suggestions.length && !_vm.selected_project
        )?[_c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({
                up: ['arrowup'],
                down: ['arrowdown'],
                tab: ['tab'],
                space: ['space'],
                backspace: ['backspace'],
            }),expression:"{\n                up: ['arrowup'],\n                down: ['arrowdown'],\n                tab: ['tab'],\n                space: ['space'],\n                backspace: ['backspace'],\n            }"}],on:{"shortkey":(event) => _vm.selectProject(null, event)}}),_c('div',{staticClass:"projects_suggestions"},[_c('ul',_vm._l((_vm.projects_suggestions),function(project,idx){return _c('li',{key:project.id,class:{
                        active:
                            _vm.selected_project &&
                            _vm.selected_project.id === project.id,
                        selected:
                            _vm.selected_project_index > -1 &&
                            idx === _vm.selected_project_index,
                    },on:{"click":function($event){return _vm.selectProject(idx)}}},[_c('span',{staticClass:"label"},[_c('b',[_vm._v(_vm._s(project.ref))]),_vm._v(" - "+_vm._s(project.name)+" ")])])}),0)])]:_vm._e(),(
            _vm.show_user &&
            _vm.filtered_user_suggestions.length &&
            !_vm.selected_user_data
        )?[_c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({
                up: ['arrowup'],
                down: ['arrowdown'],
                tab: ['tab'],
                space: ['space'],
                backspace: ['backspace'],
            }),expression:"{\n                up: ['arrowup'],\n                down: ['arrowdown'],\n                tab: ['tab'],\n                space: ['space'],\n                backspace: ['backspace'],\n            }"}],on:{"shortkey":(event) => _vm.selectUser(null, event)}}),_c('div',{staticClass:"users_suggestions"},[_c('ul',_vm._l((_vm.filtered_user_suggestions),function(usr,idx){return _c('li',{key:usr.id,class:{
                        active:
                            _vm.selected_user_data &&
                            _vm.selected_user_data.id === usr.id,
                        selected:
                            _vm.selected_user_index > -1 &&
                            idx === _vm.selected_user_index,
                    },on:{"click":function($event){return _vm.selectUser(idx)}}},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(usr.name)+" ")])])}),0)])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }