var render = function render(){var _vm=this,_c=_vm._self._c;return _c('command-layout',{attrs:{"show_back":_vm.show_back},on:{"goBack":function($event){return _vm.$emit('goBack')}}},[_c('div',{staticClass:"header",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"title"},[_vm._v("New document from external link")]),_c('div',{staticClass:"breadcrumbs"},[_c('span',[_c('svgicon',{staticClass:"breadcrumb-arrow",attrs:{"name":"triangle"}}),_vm._v(" "+_vm._s(_vm.initial_data.project?.value?.ref)+" ")],1)])]),_c('el-form',{ref:"form",staticClass:"link-form",attrs:{"slot":"body","model":_vm.form,"label-width":"80px"},slot:"body"},[_c('el-form-item',{attrs:{"label":"Name","prop":"name","rules":{
                required: true,
                message: 'Name is required',
                trigger: 'blur',
            }}},[_c('el-input',{ref:"link_name",attrs:{"placeholder":"Document name"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"backspace",undefined,$event.key,undefined))return null;return (() => _vm.handleBackspacePress()).apply(null, arguments)}},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"URL","prop":"url","rules":{
                required: true,
                message: 'URL is required',
                trigger: 'blur',
            }}},[_c('el-input',{attrs:{"placeholder":"URL to resource"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"backspace",undefined,$event.key,undefined))return null;return (() => _vm.handleBackspacePress()).apply(null, arguments)}},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}})],1),_c('div',{staticClass:"controls"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" Create ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }