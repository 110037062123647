import {USER_ROLES} from '@/enums';

const userMixin = {
    computed: {
        session_user() {
            return this.$store.getters.session_user;
        },
        role() {
            return this.$store.getters.session_user.role;
        },
        roles() {
            return this.$store.getters.userRoles;
        },

        current_user() {
            return this.$store.getters.user;
        },
        users() {
            return this.$store.getters.users;
        },

        user_is_admin() {
            return this.role.includes(USER_ROLES.ADMIN);
        },
        user_is_super_admin() {
            return this.role.includes(USER_ROLES.SUPER_ADMIN);
        },

        user_needs_password_change() {
            return !!this.session_user.password_needs_reset;
        },

        is_atlassian_authed() {
            return (
                this.$store.getters.is_atlassian_enabled &&
                this.session_user.is_atlassian_authed
            );
        },
        is_xero_authed() {
            return (
                this.$store.getters.is_xero_enabled &&
                this.session_user.is_xero_authed
            );
        },
        product_owner_role() {
            return this.$store.getters.userRoles.find(
                (role) => role.value === 'product_owner'
            );
        },
    },
};

export default userMixin;
