import { render, staticRenderFns } from "./IssueSuggestionListItem.vue?vue&type=template&id=753c26fb&scoped=true"
import script from "./IssueSuggestionListItem.vue?vue&type=script&lang=js"
export * from "./IssueSuggestionListItem.vue?vue&type=script&lang=js"
import style0 from "./IssueSuggestionListItem.vue?vue&type=style&index=0&id=753c26fb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "753c26fb",
  null
  
)

export default component.exports